import { navigate } from "gatsby";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { SigninFromCart } from "../components/signin";
import { AppState } from "../store";
import PhoneCodeType from "../types/phoneCodeType";

const SigninPage: FC = () => {
  const token = useSelector<AppState, string>(state => state.auth.token);

  if (token) {
    navigate("/order");
  }

  return (
    <Layout
      header={false}
      footer={false}
      menuState="cart"
      progressbar={true}
      progressBarStep={2}
    >
      <SEO title="Panier" />
      <SigninFromCart
        goToCodeValidation={(type: PhoneCodeType): Promise<void> =>
          navigate(`/validate-code-from-cart?type=${type}`)
        }
      />
    </Layout>
  );
};

export default SigninPage;
